import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";
import LandingLayout from "./layouts/Landing";

// Guards
import UserGuard from "./components/guards/UserGuard";
import AdminGuard from "./components/guards/AdminGuard";
import AuthGuard from "./components/guards/AuthGuard";

// Landing
import Landing from "./pages/landing/Landing";

// Dashboards
import Default from "./pages/dashboards/Default";
import Analytics from "./pages/dashboards/Analytics";
import SaaS from "./pages/dashboards/SaaS";
import Social from "./pages/dashboards/Social";
import Crypto from "./pages/dashboards/Crypto";

// Pages
import Profile from "./pages/pages/Profile";
import Settings from "./pages/pages/Settings";
import Clients from "./pages/pages/Clients";
import Projects from "./pages/pages/Projects";
import Invoice from "./pages/pages/Invoice";
import Pricing from "./pages/pages/Pricing";
import Tasks from "./pages/pages/Tasks";
import Chat from "./pages/pages/Chat";
import Blank from "./pages/pages/Blank";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";

// UI components
import Alerts from "./pages/ui/Alerts";
import Buttons from "./pages/ui/Buttons";
import Cards from "./pages/ui/Cards";
import Carousel from "./pages/ui/Carousel";
import EmbedVideo from "./pages/ui/EmbedVideo";
import General from "./pages/ui/General";
import Grid from "./pages/ui/Grid";
import Modals from "./pages/ui/Modals";
import Offcanvas from "./pages/ui/Offcanvas";
import Tabs from "./pages/ui/Tabs";
import Typography from "./pages/ui/Typography";

// Icons
import Feather from "./pages/icons/Feather";
import FontAwesome from "./pages/icons/FontAwesome";

// Forms
import Layouts from "./pages/forms/Layouts";
import BasicInputs from "./pages/forms/BasicInputs";
import InputGroups from "./pages/forms/InputGroups";
import FloatingLabels from "./pages/forms/FloatingLabels";
import AdvancedInputs from "./pages/forms/AdvancedInputs";
import Formik from "./pages/forms/Formik";
import Editors from "./pages/forms/Editors";

// Tables
import Tables from "./pages/tables/Tables";
import Pagination from "./pages/tables/Pagination";
import ColumnSorting from "./pages/tables/ColumnSorting";
import ColumnFiltering from "./pages/tables/ColumnFiltering";
import RowExpanding from "./pages/tables/RowExpanding";
import RowSelection from "./pages/tables/RowSelection";

// Charts
import Chartjs from "./pages/charts/Chartjs";
import ApexCharts from "./pages/charts/ApexCharts";

// Notifications
import Notifications from "./pages/notifications/Notifications";

// Maps
import GoogleMaps from "./pages/maps/GoogleMaps";
import VectorMaps from "./pages/maps/VectorMaps";

// Calendar
import Calendar from "./pages/calendar/Calendar";

// Documentation
import Introduction from "./pages/docs/Introduction";
import GettingStarted from "./pages/docs/GettingStarted";
import Routing from "./pages/docs/Routing";
import Auth0 from "./pages/docs/auth/Auth0";
import Cognito from "./pages/docs/auth/Cognito";
import Firebase from "./pages/docs/auth/Firebase";
import JWT from "./pages/docs/auth/JWT";
import Guards from "./pages/docs/Guards";
import APICalls from "./pages/docs/APICalls";
import Redux from "./pages/docs/Redux";
import Internationalization from "./pages/docs/Internationalization";
import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
import Deployment from "./pages/docs/Deployment";
import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
import Support from "./pages/docs/Support";
import Changelog from "./pages/docs/Changelog";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";



// New Routes
import Search from "./pages/Search";
import Product from "./pages/Product";
import Dashboard from "./pages/Dashboard";
import DashboardProductUpload from "./pages/DashboardProductUpload";
import DashboardProducts from "./pages/DashboardProducts";
import DashboardProductsTranslations from "./pages/DashboardProductsTranslations";
import DashboardProduct from "./pages/DashboardProduct";
import DashboardSettingsPricing from "./pages/DashboardSettingsPricing";
import DashboardSettingsAdmin from "./pages/DashboardSettingsAdmin";
import DashboardSettingsLogs from "./pages/DashboardSettingsLogs";
import DashboardDatabase from "./pages/DashboardDatabase";
import DashboardUsers from "./pages/DashboardUsers";
import DashboardCarts from "./pages/DashboardCarts";
import DashboardCartsTrends from "./pages/DashboardCartsTrends";
import DashboardCart from "./pages/DashboardCart";
import DashboardUser from "./pages/DashboardUser";
import Currencies from "./components/Currrencies";
import Account from "./pages/Account";
import OpenCart from "./pages/OpenCart"
import EmailVerified from "./pages/auth/EmailVerified";
import Tos from "./pages/Tos";
import { useParams } from "react-router-dom";
import { allLanguages } from "./i18n/i18n";
import Contact from "./pages/contact";

// {/* <Route path="/" element={<Home />} /> */}
// <Route path="/" element={<Search />} />
// <Route path="/search" element={<Search />} />
// <Route path="/product/:id" element={<Product />} />
// <Route path="/about" element={<About />} />
// {/* DASHBOARD */}
// <Route path='/dashboard' element={<Dashboard />}>
//   <Route index element={<Search />} />
//   <Route path="products" element={<Search />} />
//   <Route path="upload" element={<ProductUpload />}/>
// </Route>
// {/* MISSING ROUTE CATCH */}
// <Route path='*' element={<NotFound />} />

const LangWrap = ({component}) => {
  const { lang } = useParams();
  if (!allLanguages?.map(el => el?.code)?.includes(lang)) {
    return <Page404 />
  }
  return component
}

const routes = [
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "",
        element: <Search />,
      },
      {
        path: "search",
        element: <Search />,
      },
      {
        path: "cart",
        element: (
          <UserGuard>
            <OpenCart />
          </UserGuard>
        ),
      },
      {
        path: "tos",
        element: <Tos />
      },
      {
        path: "/:lang/tos",
        element: <LangWrap component={<Tos />}/>
      },
      {
        path: "contact",
        element: <Contact />
      },
    ],
  },
  {
    path: "product",
    element: <LandingLayout />,
    children: [
      {
        path: "/product/:code",
        element: <Product />,
      },
    ],
  },
  // {
  //   path: "/",
  //   element: <LandingLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Landing />,
  //     },
  //   ],
  // },
  {
    path: "account",
    element: (
      <UserGuard>
        <LandingLayout />
      </UserGuard>
    ),
    children: [
      {
        path: "",
        element: <Account />,
      },
      // {
      //   path: "search",
      //   element: <Search />,
      // },
    ],
  },
  {
    path: "email-verified",
    element: <EmailVerified />
  },
  {
    path: "reset-password",
    element: <ResetPassword />
  },
  {
    path: "dashboard",
    element: (
      <AdminGuard>
        <DashboardLayout />
      </AdminGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "products",
        element: <DashboardProducts />,
      },
      {
        path: "product/upload",
        element: <DashboardProductUpload />
      },
      {
        path: "product/:id",
        element: <DashboardProduct />,
        children: [
          { path: "details" },
          { path: "images" },
          { path: "models" },
          { path: "translations" },
          { path: "translations/:code" }
        ],
      },
      {
        path: "products/translations",
        element: <DashboardProductsTranslations />,
        children: [{ path: ":code" }],
      },
      {
        path: "users",
        element: <DashboardUsers />,
      },
      {
        path: "user/:id",
        element: <DashboardUser />,
      },
      {
        path: "carts",
        element: <DashboardCarts />,
      },
      {
        path: "carts/trends",
        element: <DashboardCartsTrends />
      },
      {
        path: "cart/:id",
        element: <DashboardCart />
      },
      {
        path: "settings/pricing",
        element: <DashboardSettingsPricing />
      },
      {
        path: "settings/currencies",
        element: <Currencies />
      },
      {
        path: "settings/database",
        element: <DashboardDatabase />
      },
      {
        path: "settings/admin",
        element: <DashboardSettingsAdmin />
      },
      {
        path: "settings/logs",
        element: <DashboardSettingsLogs />
      },
      // {
      //   path: "analytics",
      //   element: <Analytics />,
      // },
      // {
      //   path: "saas",
      //   element: <SaaS />,
      // },
      // {
      //   path: "social",
      //   element: <Social />,
      // },
      // {
      //   path: "crypto",
      //   element: <Crypto />,
      // },
    ],
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "clients",
        element: <Clients />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "invoice",
        element: <Invoice />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "tasks",
        element: <Tasks />,
      },
      {
        path: "chat",
        element: <Chat />,
      },
      {
        path: "blank",
        element: <Blank />,
      },
    ],
  },
  {
    path: "auth",
    element: (
      <AuthGuard>
        <AuthLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "ui",
    element: <DashboardLayout />,
    children: [
      {
        path: "alerts",
        element: <Alerts />,
      },
      {
        path: "buttons",
        element: <Buttons />,
      },
      {
        path: "cards",
        element: <Cards />,
      },
      {
        path: "carousel",
        element: <Carousel />,
      },
      {
        path: "embed-video",
        element: <EmbedVideo />,
      },
      {
        path: "general",
        element: <General />,
      },
      {
        path: "grid",
        element: <Grid />,
      },
      {
        path: "modals",
        element: <Modals />,
      },
      {
        path: "tabs",
        element: <Tabs />,
      },
      {
        path: "offcanvas",
        element: <Offcanvas />,
      },
      {
        path: "typography",
        element: <Typography />,
      },
    ],
  },
  {
    path: "icons",
    element: <DashboardLayout />,
    children: [
      {
        path: "feather",
        element: <Feather />,
      },
      {
        path: "font-awesome",
        element: <FontAwesome />,
      },
    ],
  },
  // {
  //   path: "forms",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "layouts",
  //       element: <Layouts />,
  //     },
  //     {
  //       path: "basic-inputs",
  //       element: <BasicInputs />,
  //     },
  //     {
  //       path: "input-groups",
  //       element: <InputGroups />,
  //     },
  //     {
  //       path: "floating-labels",
  //       element: <FloatingLabels />,
  //     },
  //   ],
  // },
  // {
  //   path: "tables",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Tables />,
  //     },
  //   ],
  // },
  // {
  //   path: "form-plugins",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "advanced-inputs",
  //       element: <AdvancedInputs />,
  //     },
  //     {
  //       path: "formik",
  //       element: <Formik />,
  //     },
  //     {
  //       path: "editors",
  //       element: <Editors />,
  //     },
  //   ],
  // },
  {
    path: "advanced-tables",
    element: <DashboardLayout />,
    children: [
      {
        path: "pagination",
        element: <Pagination />,
      },
      {
        path: "column-sorting",
        element: <ColumnSorting />,
      },
      {
        path: "column-filtering",
        element: <ColumnFiltering />,
      },
      {
        path: "row-expanding",
        element: <RowExpanding />,
      },
      {
        path: "row-selection",
        element: <RowSelection />,
      },
    ],
  },
  {
    path: "charts",
    element: <DashboardLayout />,
    children: [
      {
        path: "chartjs",
        element: <Chartjs />,
      },
      {
        path: "apexcharts",
        element: <ApexCharts />,
      },
    ],
  },
  {
    path: "notifications",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Notifications />,
      },
    ],
  },
  // {
  //   path: "maps",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "google-maps",
  //       element: <GoogleMaps />,
  //     },
  //     {
  //       path: "vector-maps",
  //       element: <VectorMaps />,
  //     },
  //   ],
  // },
  // {
  //   path: "calendar",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Calendar />,
  //     },
  //   ],
  // },
  // {
  //   path: "docs",
  //   element: <DocLayout />,
  //   children: [
  //     {
  //       path: "introduction",
  //       element: <Introduction />,
  //     },
  //     {
  //       path: "getting-started",
  //       element: <GettingStarted />,
  //     },
  //     {
  //       path: "routing",
  //       element: <Routing />,
  //     },
  //     {
  //       path: "auth/auth0",
  //       element: <Auth0 />,
  //     },
  //     {
  //       path: "auth/cognito",
  //       element: <Cognito />,
  //     },
  //     {
  //       path: "auth/firebase",
  //       element: <Firebase />,
  //     },
  //     {
  //       path: "auth/jwt",
  //       element: <JWT />,
  //     },
  //     {
  //       path: "guards",
  //       element: <Guards />,
  //     },
  //     {
  //       path: "api-calls",
  //       element: <APICalls />,
  //     },
  //     {
  //       path: "redux",
  //       element: <Redux />,
  //     },
  //     {
  //       path: "internationalization",
  //       element: <Internationalization />,
  //     },
  //     {
  //       path: "environment-variables",
  //       element: <EnvironmentVariables />,
  //     },
  //     {
  //       path: "eslint-and-prettier",
  //       element: <ESLintAndPrettier />,
  //     },
  //     {
  //       path: "deployment",
  //       element: <Deployment />,
  //     },
  //     {
  //       path: "migrating-to-next-js",
  //       element: <MigratingToNextJS />,
  //     },
  //     {
  //       path: "support",
  //       element: <Support />,
  //     },
  //     {
  //       path: "changelog",
  //       element: <Changelog />,
  //     },
  //   ],
  // },
  // {
  //   path: "private",
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "",
  //       element: <ProtectedPage />,
  //     },
  //   ],
  // },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
