import React from "react";
import { Outlet } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import dashboardItems from "../components/sidebar/dashboardItems";
import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";

const Dashboard = ({ children }) => {

  const { t, i18n} = useTranslation();
  const locale = i18n?.language;
  
  const isTranslator = useAuth()?.type  == "translator"

  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar items={dashboardItems({locale, isTranslator})} />
        <Main>
          <Navbar />
          <Content>
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
      {/* <Settings /> */}
    </React.Fragment>
  );
}

export default Dashboard;
